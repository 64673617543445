.text-success {
  color: var(--status-success-color);
}
.text-warning {
  color: var(--status-warning-color);
}
.text-danger {
  color: var(--status-danger-color);
}

.text-primary {
  color: var(--primary);
}
.text-secondary {
  color: var(--secondary);
}
.text-tertiary {
  color: var(--tertiary);
}
.text-light {
  color: var(--light);
}
.text-medium {
  color: var(--medium);
}
.text-dark {
  color: var(--dark);
}