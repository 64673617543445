@import 'global-settings';

/* Ionic Variables and Theming. */
/* This is just a placeholder file For more info, please see: */
/* https://ionicframework.com/docs/theming/basics */

/* To quickly generate your own theme, check out the color generator */
/* https://ionicframework.com/docs/theming/color-generator */

:root {
  --color-bright: #{$color-bright};
  --color-bright-rgb: #{hexToRGB($color-bright)};
  --color-dark: #{$color-dark};
  --color-dark-rgb: #{hexToRGB($color-dark)};

  @each $color,
  $value in $theme-colors {
    --#{$color}: #{$value};

    @if (is-dark($value)=='1') {
      --#{$color}-contrast: var(--color-bright);
      --#{$color}-contrast-rgb: var(--color-bright-rgb);
    }

    @else {
      --#{$color}-contrast: var(--color-dark);
      --#{$color}-contrast-rgb: var(--color-dark-rgb);
    }

    --ion-color-#{$color}: var(--#{$color});
    --ion-color-#{$color}-rgb: #{hexToRGB($value)};
    --ion-color-#{$color}-contrast: var(--#{$color}-contrast);
    --ion-color-#{$color}-contrast-rgb: var(--#{$color}-contrast-rgb);
    --ion-color-#{$color}-shade: #{get-color-shade($value)};
    --ion-color-#{$color}-tint: #{get-color-tint($value)};
    --#{$color}-hover: var(--ion-color-#{$color}-shade);
    --#{$color}-success: var(--#{$color}-contrast);
    --#{$color}-warning: var(--#{$color}-contrast);
    --#{$color}-danger: var(--#{$color}-contrast);
  }

  @each $name,
  $color in $color-steps {
    --color-#{$name}: #{$color};
    --color-#{$name}-contrast: #{text-color-over($color)};
    --color-#{$name}-rgb: #{hexToRGB($color)};
    --ion-color-#{$name}: var(--color-#{$name});
  }

  @each $name,
  $color in $special-colors {
    --color-#{$name}: #{$color};
    --color-#{$name}-contrast: #{text-color-over($color)};
    --color-#{$name}-rgb: #{hexToRGB($color)};
  }

  // Backgrounds
  // https://ionicframework.com/docs/theming/themes
  --body-bg: var(--light);
  --ion-background-color: var(--body-bg);
  --ion-background-color-rgb: var(--ion-color-light-rgb);
  --ion-backdrop-opacity: 0.5;

  --box-opacity: 0.5;
  --menu-opacity: 0.8;
  --menu-opacity-hover: 0.9;

  --ion-toolbar-background: var(--color-50);
  --ion-card-background: var(--color-100);

  // Default Texts on white default background:
  --body-bg-contrast: var(--light-contrast);
  --ion-text-color: var(--body-bg-contrast);
  --ion-text-color-rgb: var(--ion-color-light-contrast-rgb);
  --status-success-color: var(--light-success);
  --status-warning-color: var(--light-warning);
  --status-danger-color: var(--light-danger);

  // Font & Fontsizes
  // -----------
  --ion-font-family: Raleway,
  -apple-system,
  system-ui,
  Segoe UI,
  Roboto,
  Ubuntu,
  Cantarell,
  Noto Sans,
  sans-serif,
  BlinkMacSystemFont,
  Helvetica Neue,
  Arial;
  --ion-headline-font-family: Fira Sans,
  -apple-system,
  system-ui,
  Segoe UI,
  Roboto,
  Ubuntu,
  Cantarell,
  Noto Sans,
  sans-serif,
  BlinkMacSystemFont,
  Helvetica Neue,
  Arial;
  --ion-code-font-family: Fira Code,
  -apple-system,
  system-ui,
  Segoe UI,
  Roboto,
  Ubuntu,
  Cantarell,
  Noto Sans,
  sans-serif,
  BlinkMacSystemFont,
  Helvetica Neue,
  Arial;

  --font-size-small: 14px;
  --font-size: 16px;
  --font-size-big: 18px;

  // Grid Variables
  // -----------
  --ion-grid-columns: 12;
  --ion-grid-width-xs: 100%;
  --ion-grid-width-sm: map.get($grid-breakpoints, 'sm');
  --ion-grid-width-md: map.get($grid-breakpoints, 'md');
  --ion-grid-width-lg: map.get($grid-breakpoints, 'lg');
  --ion-grid-width-xl: map.get($grid-breakpoints, 'xl');

  --step-sm: #{$step-sm};
  --step-md: #{$step-md};
  --step-lg: #{$step-lg};
  --step-xl: #{$step-xl};

  // Background-elements
  // -----------
  --element-bg: var(--light);
  --element-bg-rgb: var(--ion-color-light-rgb);
  --element-bg-contrast: var(--light-contrast);
  --element-bg-contrast-rgb: var(--ion-color-light-contrast-rgb);
  --element-bg-shade: var(--ion-color-light-shade);
  --element-bg-tint: var(--ion-color-light-tint);
  --element-bg-hover: var(--ion-color-light-shade);
  --element-bg-hover-contrast: var(--light-contrast);
  --element-bg-success: var(--light-success);
  --element-bg-warning: var(--light-warning);
  --element-bg-danger: var(--light-danger);

  --element-bg-active: var(--secondary);
  --element-bg-active-rgb: var(--ion-color-secondary-rgb);
  --element-bg-active-contrast: var(--secondary-contrast);
  --element-bg-active-contrast-rgb: var(--ion-color-secondary-contrast-rgb);
  --element-bg-active-shade: var(--ion-color-secondary-shade);
  --element-bg-active-tint: var(--ion-color-secondary-tint);
  --element-bg-active-hover: var(--element-bg-active-shade);
  --element-bg-active-hover-contrast: var(--element-bg-active-contrast);
  --element-bg-active-success: var(--secondary-success);
  --element-bg-active-warning: var(--secondary-warning);
  --element-bg-active-danger: var(--secondary-danger);

  // Cards
  // ---------
  --card-font-size: var(--font-size);
  --card-border-radius: var(--border-radius);

  --card-bg: var(--tertiary);
  --card-bg-rgb: var(--ion-color-tertiary-rgb);
  --card-bg-contrast: var(--tertiary-contrast);
  --card-bg-contrast-rgb: var(--ion-color-tertiary-contrast-rgb);
  --card-bg-shade: var(--ion-color-tertiary-shade);
  --card-bg-tint: var(--ion-color-tertiary-tint);
  --card-bg-hover: var(--ion-color-tertiary-tint);
  --card-bg-hover-contrast: var(--tertiary-contrast);
  --card-bg-success: var(--tertiary-success);
  --card-bg-warning: var(--tertiary-warning);
  --card-bg-danger: var(--tertiary-danger);

  --card-bg-active: var(--element-bg-active);
  --card-bg-active-rgb: var(--element-bg-active-rgb);
  --card-bg-active-contrast: var(--element-bg-active-contrast);
  --card-bg-active-contrast-rgb: var(--element-bg-active-contrast-rgb);
  --card-bg-active-shade: var(--element-bg-active-shade);
  --card-bg-active-tint: var(--element-bg-active-tint);
  --card-bg-active-hover: var(--card-bg-active-shade);
  --card-bg-active-hover-contrast: var(--card-bg-active-contrast);
  --card-bg-active-success: var(--element-bg-active-success);
  --card-bg-active-warning: var(--element-bg-active-warning);
  --card-bg-active-danger: var(--element-bg-active-danger);

  // Ion-segment
  // ---------
  --segment-bg: var(--light);
  --segment-bg-contrast: var(--light-contrast);
  --segment-bg-hover: var(--light-hover);
  --segment-bg-active: var(--secondary);
  --segment-bg-active-contrast: var(--secondary-contrast);
  --segment-bg-active-hover: var(--secondary-hover);
  --segment-bg-disabled: var(--segment-bg);
  --segment-bg-disabled-contrast: var(--medium-contrast);
  --segment-bg-disabled-hover: var(--segment-bg);

  --shadow-color: 225deg 7% 64%;
  --shadow-elevation-low: 0.5px 0.5px 0.7px hsl(var(--shadow-color) / 0.43),
  0.7px 0.8px 1px -1.7px hsl(var(--shadow-color) / 0.35),
  1.8px 2px 2.6px -3.4px hsl(var(--shadow-color) / 0.27);
  --shadow-elevation-medium: 0.5px 0.5px 0.7px hsl(var(--shadow-color) / 0.45),
  1.2px 1.3px 1.7px -1.1px hsl(var(--shadow-color) / 0.39),
  3.5px 3.8px 5.1px -2.3px hsl(var(--shadow-color) / 0.32),
  9.1px 9.8px 13.1px -3.4px hsl(var(--shadow-color) / 0.26);
  --shadow-elevation-high: 0.5px 0.5px 0.7px hsl(var(--shadow-color) / 0.41),
  1.8px 2px 2.6px -0.5px hsl(var(--shadow-color) / 0.39),
  3.5px 3.8px 5.1px -1px hsl(var(--shadow-color) / 0.36),
  6.3px 6.8px 9.1px -1.5px hsl(var(--shadow-color) / 0.33),
  10.9px 11.7px 15.7px -2px hsl(var(--shadow-color) / 0.3),
  18px 19.5px 26.1px -2.5px hsl(var(--shadow-color) / 0.27),
  28.5px 30.8px 41.2px -3px hsl(var(--shadow-color) / 0.24),
  43.1px 46.5px 62.3px -3.4px hsl(var(--shadow-color) / 0.21);
}

/*
   * Dark Colors
   * -------------------------------------------
   */

body.dark,
.md body.dark,
.ios body.dark {
  --color-bright: #{$dark-color-bright};
  --color-bright-rgb: #{hexToRGB($dark-color-bright)};
  --color-dark: #{$dark-color-dark};
  --color-dark-rgb: #{hexToRGB($dark-color-dark)};

  @each $color,
  $value in $dark-theme-colors {
    --#{$color}: #{$value};

    @if (is-dark($value)=='1') {
      --#{$color}-contrast: var(--color-bright);
      --#{$color}-contrast-rgb: var(--color-bright-rgb);
    }

    @else {
      --#{$color}-contrast: var(--color-dark);
      --#{$color}-contrast-rgb: var(--color-dark-rgb);
    }

    --ion-color-#{$color}: var(--#{$color});
    --ion-color-#{$color}-rgb: #{hexToRGB($value)};
    --ion-color-#{$color}-contrast: var(--#{$color}-contrast);
    --ion-color-#{$color}-contrast-rgb: var(--#{$color}-contrast-rgb);
    --ion-color-#{$color}-shade: #{get-color-shade($value)};
    --ion-color-#{$color}-tint: #{get-color-tint($value)};
    --#{$color}-hover: var(--ion-color-#{$color}-shade);
    --#{$color}-success: var(--#{$color}-contrast);
    --#{$color}-warning: var(--#{$color}-contrast);
    --#{$color}-danger: var(--#{$color}-contrast);

    @if($color=='tertiary') {
      --ion-color-#{$color}-tint: #{get-color-tint($value, 8%)};
    }
  }

  @each $name,
  $color in $dark-color-steps {
    --color-#{$name}: #{$color};
    --color-#{$name}-contrast: #{text-color-over($color)};
    --color-#{$name}-rgb: #{hexToRGB($color)};
    --ion-color-#{$name}: var(--color-#{$name});
  }

  @each $name,
  $color in $special-colors {
    --color-#{$name}: #{$color};
    --color-#{$name}-contrast: #{text-color-over($color)};
    --color-#{$name}-rgb: #{hexToRGB($color)};
  }

  // Backgrounds
  // https://ionicframework.com/docs/theming/themes
  --body-bg: var(--light);
  --ion-background-color: var(--body-bg);
  --ion-background-color-rgb: var(--ion-color-light-rgb);
  --ion-backdrop-opacity: 0.5;

  --box-opacity: 0.8;
  --menu-opacity: 0.8;
  --menu-opacity-hover: 0.9;

  --ion-toolbar-background: var(--color-50);
  --ion-card-background: var(--color-100);

  // Default Texts on white default background:
  --body-bg-contrast: var(--light-contrast);
  --ion-text-color: var(--body-bg-contrast);
  --ion-text-color-rgb: var(--ion-color-light-contrast-rgb);
  --status-success-color: var(--light-success);
  --status-warning-color: var(--light-warning);
  --status-danger-color: var(--light-danger);

  // Font & Fontsizes
  // -----------
  --ion-font-family: Raleway,
  -apple-system,
  system-ui,
  Segoe UI,
  Roboto,
  Ubuntu,
  Cantarell,
  Noto Sans,
  sans-serif,
  BlinkMacSystemFont,
  Helvetica Neue,
  Arial;
  --ion-headline-font-family: Fira Sans,
  -apple-system,
  system-ui,
  Segoe UI,
  Roboto,
  Ubuntu,
  Cantarell,
  Noto Sans,
  sans-serif,
  BlinkMacSystemFont,
  Helvetica Neue,
  Arial;
  --ion-code-font-family: Fira Code,
  -apple-system,
  system-ui,
  Segoe UI,
  Roboto,
  Ubuntu,
  Cantarell,
  Noto Sans,
  sans-serif,
  BlinkMacSystemFont,
  Helvetica Neue,
  Arial;

  --font-size-small: 14px;
  --font-size: 16px;
  --font-size-big: 18px;

  // Grid Variables
  // -----------
  --ion-grid-columns: 12;
  --ion-grid-width-xs: 100%;
  --ion-grid-width-sm: map.get($grid-breakpoints, 'sm');
  --ion-grid-width-md: map.get($grid-breakpoints, 'md');
  --ion-grid-width-lg: map.get($grid-breakpoints, 'lg');
  --ion-grid-width-xl: map.get($grid-breakpoints, 'xl');

  --step-sm: #{$step-sm};
  --step-md: #{$step-md};
  --step-lg: #{$step-lg};
  --step-xl: #{$step-xl};

  // Background-elements
  // -----------
  --element-bg: var(--light);
  --element-bg-rgb: var(--ion-color-light-rgb);
  --element-bg-contrast: var(--light-contrast);
  --element-bg-contrast-rgb: var(--ion-color-light-contrast-rgb);
  --element-bg-shade: var(--ion-color-light-shade);
  --element-bg-tint: var(--ion-color-light-tint);
  --element-bg-hover: var(--ion-color-light-tint);
  --element-bg-hover-contrast: var(--light-contrast);
  --element-bg-success: var(--light-success);
  --element-bg-warning: var(--light-warning);
  --element-bg-danger: var(--light-danger);

  --element-bg-active: var(--secondary);
  --element-bg-active-rgb: var(--ion-color-secondary-rgb);
  --element-bg-active-contrast: var(--secondary-contrast);
  --element-bg-active-contrast-rgb: var(--ion-color-secondary-contrast-rgb);
  --element-bg-active-shade: var(--ion-color-secondary-shade);
  --element-bg-active-tint: var(--ion-color-secondary-tint);
  --element-bg-active-hover: var(--element-bg-active-shade);
  --element-bg-active-hover-contrast: var(--element-bg-active-contrast);
  --element-bg-active-success: var(--secondary-success);
  --element-bg-active-warning: var(--secondary-warning);
  --element-bg-active-danger: var(--secondary-danger);

  // Cards
  // ---------
  --card-font-size: var(--font-size);
  --card-border-radius: var(--border-radius);

  --card-bg: var(--tertiary);
  --card-bg-rgb: var(--ion-color-tertiary-rgb);
  --card-bg-contrast: var(--tertiary-contrast);
  --card-bg-contrast-rgb: var(--ion-color-tertiary-contrast-rgb);
  --card-bg-shade: var(--ion-color-tertiary-shade);
  --card-bg-tint: var(--ion-color-tertiary-tint);
  --card-bg-hover: var(--ion-color-tertiary-tint);
  --card-bg-hover-contrast: var(--tertiary-contrast);
  --card-bg-success: var(--tertiary-success);
  --card-bg-warning: var(--tertiary-warning);
  --card-bg-danger: var(--tertiary-danger);

  --card-bg-active: var(--element-bg-active);
  --card-bg-active-rgb: var(--element-bg-active-rgb);
  --card-bg-active-contrast: var(--element-bg-active-contrast);
  --card-bg-active-contrast-rgb: var(--element-bg-active-contrast-rgb);
  --card-bg-active-shade: var(--element-bg-active-shade);
  --card-bg-active-tint: var(--element-bg-active-tint);
  --card-bg-active-hover: var(--card-bg-active-shade);
  --card-bg-active-hover-contrast: var(--card-bg-active-contrast);
  --card-bg-active-success: var(--element-bg-active-success);
  --card-bg-active-warning: var(--element-bg-active-warning);
  --card-bg-active-danger: var(--element-bg-active-danger);

  // Ion-segment
  // ---------
  --segment-bg: var(--light);
  --segment-bg-contrast: var(--light-contrast);
  --segment-bg-hover: var(--light-hover);
  --segment-bg-active: var(--secondary);
  --segment-bg-active-contrast: var(--secondary-contrast);
  --segment-bg-active-hover: var(--secondary-hover);
  --segment-bg-disabled: var(--segment-bg);
  --segment-bg-disabled-contrast: var(--medium-contrast);
  --segment-bg-disabled-hover: var(--segment-bg);

  --shadow-color: 210deg 44% 4%;
  --shadow-elevation-low: 0.5px 0.5px 0.7px hsl(var(--shadow-color) / 0.43),
  0.7px 0.8px 1px -1.7px hsl(var(--shadow-color) / 0.35),
  1.8px 2px 2.6px -3.4px hsl(var(--shadow-color) / 0.27);
  --shadow-elevation-medium: 0.5px 0.5px 0.7px hsl(var(--shadow-color) / 0.45),
  1.2px 1.3px 1.7px -1.1px hsl(var(--shadow-color) / 0.39),
  3.5px 3.8px 5.1px -2.3px hsl(var(--shadow-color) / 0.32),
  9.1px 9.8px 13.1px -3.4px hsl(var(--shadow-color) / 0.26);
  --shadow-elevation-high: 0.5px 0.5px 0.7px hsl(var(--shadow-color) / 0.41),
  1.8px 2px 2.6px -0.5px hsl(var(--shadow-color) / 0.39),
  3.5px 3.8px 5.1px -1px hsl(var(--shadow-color) / 0.36),
  6.3px 6.8px 9.1px -1.5px hsl(var(--shadow-color) / 0.33),
  10.9px 11.7px 15.7px -2px hsl(var(--shadow-color) / 0.3),
  18px 19.5px 26.1px -2.5px hsl(var(--shadow-color) / 0.27),
  28.5px 30.8px 41.2px -3px hsl(var(--shadow-color) / 0.24),
  43.1px 46.5px 62.3px -3.4px hsl(var(--shadow-color) / 0.21);
}