ion-card {
  --background: var(--card-bg);
  --color: var(--card-bg-contrast);
  box-shadow: var(--shadow-elevation-medium);
  transition: all 0.2s;

  ion-card-title,
  ion-card-subtitle,
  ion-card-header,
  ion-card-content,
  ion-card-footer {
    --color: var(--card-bg-contrast);
  }

  ion-card-title {
    font-family: var(--ion-headline-font-family);
  }

  ion-card-header {
    &>*:first-child {
      margin-top: 0;
    }

    &>*:last-child {
      margin-bottom: 0;
    }
  }

  &>.card-img-top {
    position: relative;
    display: block;
    width: 100%;
    height: auto;
    background: var(--image-bg);
    margin-top: 0;
    margin-left: 0;
    margin-right: 0;

    figure,
    img {
      margin-top: 0;
      margin-left: 0;
      margin-right: 0;
    }

    app-lazy-picture {
      width: 100%;
    }
  }

  &.ion-activatable {
    // transition: all 0.4s ease;

    ion-card-title,
    ion-card-header,
    ion-card-content,
    ion-card-footer {
      // transition: all 0.4s ease;
    }

    &:hover,
    &:focus {
      // --background: var(--card-bg-hover);
      // --color: var(--card-bg-hover-contrast);
      box-shadow: var(--shadow-elevation-low);
      // top: 2px;
      // left: 2px;

      ion-card-title,
      ion-card-header,
      ion-card-content,
      ion-card-footer {
        // --color: var(--card-bg-hover-contrast);
      }
    }
  }

  &.color-on-hover {

    .card-img-top {
      background-color: rgba(var(--color-dark-rgb), 0.8);
    }

    figure {
      // filter: grayscale(100%) contrast(1) brightness(90%) opacity(.6);
      filter: grayscale(20%) contrast(1) brightness(100%) opacity(.8);
      transition: all 0.2s cubic-bezier(0.64, 0.042, 0.42, 1) 0s;
      // mix-blend-mode: screen;
    }

    &:hover,
    &:focus {
      --background: var(--card-bg-hover);
      --color: var(--card-bg-hover-contrast);

      figure {
        filter: none;
        mix-blend-mode: normal;
      }
    }
  }

  &.horizontal-layout {
    @include media-breakpoint-up(sm) {
      position: relative;

      &.ion-activatable {
        &::part(native) {
          position: relative;
          display: flex;
          flex-direction: row;
          align-items: stretch;

        }
      }

      &:not(.ion-activatable) {
        display: flex;
        flex-direction: row;
      }

      ion-card-header,
      .card-img-top {
        flex: 1 1 200px;
        width: 50%;
      }

      ion-card-header {
        position: relative;
        display: flex;
        flex-direction: column;

        &>*:first-child {
          margin-top: 16px;
        }

        ion-card-subtitle {
          flex: 1 1 auto;
        }
      }

      .card-img-top {
        height: inherit;
        min-height: 100px;

        &::before {
          display: none;
        }

        &>.ar-content {
          position: relative;
          margin: 0;
          object-fit: cover;
          font-family: 'object-fit: cover';
        }
      }

      &.img-right {
        .card-img-top {
          order: 3;
        }
      }
    }

    ion-card-content {
      display: none;
    }
  }
}