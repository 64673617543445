// @import "izmir";
@import 'animate.css/animate.min.css';
@import 'lightgallery';
@import 'swiper';
@import 'lazysizes';

.c4-izmir {
  --primary-color: var(--secondary);
  --text-color: var(--secondary-contrast);
  --secondary-color: var(--primary);
  --transition-duration: 500ms;
  --border-width: 4px;
  --overlay-opacity: 0.5;
}
