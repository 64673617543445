/*
 * Gives a fixed aspect ratio to an HTML container.
 *
 * Use (for a 3 to 1 aspect ratio):
 * 	 <div class="aspect-ratio ar-3-1">
 * 	 	<div class="ar-content">
 * 	 		// Here could be contents of your choice...
 * 	 	</div>
 * 	 </div>
 */

.aspect-ratio {
  position: relative;
  overflow: hidden;

  &:before {
    display: block;
    content: '';
    width: 100%;
    padding-top: 100%;
  }
  & > .ar-content {
    position: absolute;
    display: flex;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    margin-left: 0;
    margin-right: 0;

    img,
    picture,
    &.img-fluid {
      width: 100%;
      height: 100%;
      object-fit: cover;
      font-family: 'object-fit: cover';

      &.image-contain {
        object-fit: contain;
        font-family: 'object-fit: contain';
      }
    }

    &.bg-image {
      background-size: 100%;
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center center;

      &.image-contain {
        background-size: contain;
      }
    }
  }

  & > img.ar-content,
  & > picture.ar-content,
  & > div.ar-content {
    object-fit: cover;
    font-family: 'object-fit: cover';
  }

  &.ar-1-1:before {
    padding-top: 100%;
  }
  &.ar-2-1:before {
    padding-top: 50%;
  }
  &.ar-1-2:before {
    padding-top: 200%;
  }
  &.ar-3-1:before {
    padding-top: 33.33%;
  }
  &.ar-1-3:before {
    padding-top: 300%;
  }
  &.ar-4-1:before {
    padding-top: 25%;
  }
  &.ar-1-4:before {
    padding-top: 400%;
  }
  &.ar-4-3:before {
    padding-top: 75%;
  }
  &.ar-3-4:before {
    padding-top: 133.33%;
  }
  &.ar-16-9:before {
    padding-top: 56.25%;
  }
  &.ar-9-16:before {
    padding-top: 177.77%;
  }
  &.ar-5-2:before {
    padding-top: 40%;
  }
  &.ar-8-5:before {
    padding-top: 62.5%;
  }
}
