html {
  scroll-behavior: smooth;
}

.main-wrapper {
  position: relative;
  height: auto;
  min-height: 100%;
  display: flex;
  flex-direction: column;

  &>app-footer {
    position: relative;
    flex: 1 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
  }
}

app-footer {
  position: relative;
  flex: 1 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}