$themeColor: $primary;

@import 'swiper/scss';
// @import '~swiper/css/navigation';
// @import '~swiper/css/pagination';

.swiper {
  width: 100%;
  height: 100%;
}

.swiper-container {
  height: auto;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  height: auto;
  width: auto;

  img,
  app-lazy-picture,
  picture {
    position: relative;
    margin: 0;
    display: block;
    width: auto;
    height: 100%;
    max-width: 100%;
  }
}

.swiper-button-prev,
.swiper-button-next {
  padding: 8px;
  background: rgba(var(--ion-color-light-rgb), var(--menu-opacity));
  color: var(--light-contrast);

  &:after {
    font-size: calc(var(--swiper-navigation-size) / 1.6);
  }
}
