/* raleway-regular - latin */
@font-face {
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 400;
  src: url('../../assets/fonts/raleway/raleway-v18-latin-regular.eot');
  /* IE9 Compat Modes */
  src: local(''),
    url('../../assets/fonts/raleway/raleway-v18-latin-regular.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */
    url('../../assets/fonts/raleway/raleway-v18-latin-regular.woff2') format('woff2'),
    /* Super Modern Browsers */
    url('../../assets/fonts/raleway/raleway-v18-latin-regular.woff') format('woff'),
    /* Modern Browsers */
    url('../../assets/fonts/raleway/raleway-v18-latin-regular.ttf') format('truetype'),
    /* Safari, Android, iOS */
    url('../../assets/fonts/raleway/raleway-v18-latin-regular.svg#Raleway') format('svg');
  /* Legacy iOS */
  font-display: swap;
}

/* raleway-700 - latin */
@font-face {
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 700;
  src: url('../../assets/fonts/raleway/raleway-v18-latin-700.eot');
  /* IE9 Compat Modes */
  src: local(''),
    url('../../assets/fonts/raleway/raleway-v18-latin-700.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */
    url('../../assets/fonts/raleway/raleway-v18-latin-700.woff2') format('woff2'),
    /* Super Modern Browsers */
    url('../../assets/fonts/raleway/raleway-v18-latin-700.woff') format('woff'),
    /* Modern Browsers */
    url('../../assets/fonts/raleway/raleway-v18-latin-700.ttf') format('truetype'),
    /* Safari, Android, iOS */
    url('../../assets/fonts/raleway/raleway-v18-latin-700.svg#Raleway') format('svg');
  /* Legacy iOS */
  font-display: swap;
}

/* raleway-italic - latin */
@font-face {
  font-family: 'Raleway';
  font-style: italic;
  font-weight: 400;
  src: url('../../assets/fonts/raleway/raleway-v18-latin-italic.eot');
  /* IE9 Compat Modes */
  src: local(''),
    url('../../assets/fonts/raleway/raleway-v18-latin-italic.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */
    url('../../assets/fonts/raleway/raleway-v18-latin-italic.woff2') format('woff2'),
    /* Super Modern Browsers */
    url('../../assets/fonts/raleway/raleway-v18-latin-italic.woff') format('woff'),
    /* Modern Browsers */
    url('../../assets/fonts/raleway/raleway-v18-latin-italic.ttf') format('truetype'),
    /* Safari, Android, iOS */
    url('../../assets/fonts/raleway/raleway-v18-latin-italic.svg#Raleway') format('svg');
  /* Legacy iOS */
  font-display: swap;
}

/* fira-sans-600 - latin */
@font-face {
  font-family: 'Fira Sans';
  font-style: normal;
  font-weight: 400;
  src: url('../../assets/fonts/fira-sans/fira-sans-v10-latin-600.eot');
  /* IE9 Compat Modes */
  src: local(''),
    url('../../assets/fonts/fira-sans/fira-sans-v10-latin-600.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */
    url('../../assets/fonts/fira-sans/fira-sans-v10-latin-600.woff2') format('woff2'),
    /* Super Modern Browsers */
    url('../../assets/fonts/fira-sans/fira-sans-v10-latin-600.woff') format('woff'),
    /* Modern Browsers */
    url('../../assets/fonts/fira-sans/fira-sans-v10-latin-600.ttf') format('truetype'),
    /* Safari, Android, iOS */
    url('../../assets/fonts/fira-sans/fira-sans-v10-latin-600.svg#FiraSans') format('svg');
  /* Legacy iOS */
  font-display: swap;
}

@font-face {
  font-family: 'Fira Sans';
  font-style: normal;
  font-weight: 200;
  src: url('../../assets/fonts/fira-sans/fira-sans-v10-latin-200.eot');
  /* IE9 Compat Modes */
  src: local(''),
    url('../../assets/fonts/fira-sans/fira-sans-v10-latin-200.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */
    url('../../assets/fonts/fira-sans/fira-sans-v10-latin-200.woff2') format('woff2'),
    /* Super Modern Browsers */
    url('../../assets/fonts/fira-sans/fira-sans-v10-latin-200.woff') format('woff'),
    /* Modern Browsers */
    url('../../assets/fonts/fira-sans/fira-sans-v10-latin-200.ttf') format('truetype'),
    /* Safari, Android, iOS */
    url('../../assets/fonts/fira-sans/fira-sans-v10-latin-200.svg#FiraSans') format('svg');
  /* Legacy iOS */
  font-display: swap;
}

@font-face {
  font-family: 'Fira Code';
  font-style: normal;
  font-weight: 400;
  src: url('../../assets/fonts/fira-code/fira-code-v10-latin-regular.eot');
  /* IE9 Compat Modes */
  src: local(''),
    url('../../assets/fonts/fira-code/fira-code-v10-latin-regular.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */
    url('../../assets/fonts/fira-code/fira-code-v10-latin-regular.woff2') format('woff2'),
    /* Super Modern Browsers */
    url('../../assets/fonts/fira-code/fira-code-v10-latin-regular.woff') format('woff'),
    /* Modern Browsers */
    url('../../assets/fonts/fira-code/fira-code-v10-latin-regular.ttf') format('truetype'),
    /* Safari, Android, iOS */
    url('../../assets/fonts/fira-code/fira-code-v10-latin-regular.svg#FiraCode') format('svg');
  /* Legacy iOS */
  font-display: swap;
}